import React from 'react';

const Button = ({
  text, outline, bg, rightIcon, leftIcon, submit,
}) => {
  const getBg = bg === 'primary' ? 'bg-button hover:bg-navyBlue' : 'bg-red hover:bg-button';

  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={`py-3 sm:px-7 rounded-full inline-flex items-center px-4 ${
        !outline ? getBg : 'border-2 border-solid border-button'
      }`}
      style={{ transition: 'background-color .6s ease' }}
    >
      {leftIcon && <img src={leftIcon} className="w-5 h-5 mr-4" alt={text} />}
      <span className={outline ? 'text-button' : 'text-white'}>{text}</span>
      {rightIcon && <img src={rightIcon} className="w-5 h-5 ml-4" alt={text} />}
    </button>
  );
};

export default Button;
