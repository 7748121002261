/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description, lang, meta, title,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const schemaMarkup = [
    {
      '@context': 'https://schema.org/',
      '@type': 'FAQPage',
      mainEntity: [
        {
          '@type': 'Question',
          name: 'WHAT DO YOU MEAN BY TAIL SPEND?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'It’s different for each client we work with. For some customers, it might be about non-strategic indirect purchases below a threshold of £200k. For others it might be anything below £50k. We know how busy procurement teams are and we’re here to support you with whatever you need, so you can focus on larger purchasing.',
          },
        },
        {
          '@type': 'Question',
          name: 'WHAT IS THE AIM OF SPEND ANALYSIS?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'Reviewing three years of spend data helps us see where your spending has been effective and where there is room for improvement. We identify areas and behaviours with opportunities for savings in the immediate short-term, mid-term and long-term. Our analysis gives you the insight you need to better manage future spend, and monitor it in real-time on the Maistro platform.',
          },
        },
        {
          '@type': 'Question',
          name:
            'HOW LONG DOES IT TAKE TO FIND A SUPPLIER FOR MY REQUIREMENTS USING YOUR PLATFORM?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'Typically, clients start working with new, vetted and tendered suppliers within 14 working days. But for urgent contracts, using our platform, our team can source, vet and deliver a shortlist of suitable providers in just a few working days. Talk to us about what you need!',
          },
        },
        {
          '@type': 'Question',
          name:
            'WHAT CHECKS DO YOU DO TO ENSURE THE SUPPLIERS YOU PRESENT ARE SUITABLE?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'We know quality and reliability is critical. Maistro goes the extra mile in vetting suppliers, with credit, financial and professional integrity checks on all those shortlisted. We’re here to give you peace of mind.',
          },
        },
        {
          '@type': 'Question',
          name: 'HOW DOES THE CONTRACTING AND INVOICING WORK?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'For most of our clients, Maistro acts as the primary contractor on behalf of the service provider. That means you contract with us, and we contract with them. It also means you can get working with any supplier in a matter of days. And we can group multiple supplier costs on one single monthly invoice, itemised for your records. Plus, when your stakeholders are happy with the work, we handle the payments.',
          },
        },
        {
          '@type': 'Question',
          name:
            'CAN I KEEP TRACK OF BUDGET SPEND AND CONTRACT RENEWALS USING YOUR PLATFORM?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'Yes, the Maistro platform is a powerful spend management tool designed to provide your procurement and finance teams with the real-time data they need. You can see quarterly, monthly or weekly spend patterns across all departments and you’ll get notifications about contracts due to expire, so you can choose whether to renew or re-tender.',
          },
        },
      ],
    },
    {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: 'Buy any service at speed',
      image: 'https://maistro.com/images/new_dash_fade.png',
      description:
        'An easy to use platform that anyone can use. Maistro harnesses powerful automation to guide buyers through the process with procurement experts on hand to step in and help along the way.',
      brand: {
        '@type': 'Brand',
        name: 'Maistro',
      },
      sku: 'Maistro',
      gtin8: 'Maistro',
      mpn: 'Maistro',
    },
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      name: 'Maistro',
      alternateName: 'Maistro software service provider',
      url: 'https://maistro.com/company/',
      logo: 'https://maistro.com/',
      sameAs: [
        'https://twitter.com/liveqrcode',
        'https://www.facebook.com/Live-QR-Code-Generator-100151075909253',
      ],
    },
    {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: 'Maistro',
      image: 'https://www.maistro.com/images/header.png',
      '@id': '',
      url: 'https://maistro.com/',
      telephone: '+44 (0)800 048 8664',
      priceRange: '~',
      address: {
        '@type': 'PostalAddress',
        streetAddress:
          'Rowan House North, 1 The Professional Quarter, Shrewsbury Business Park, Shrewsbury',
        addressLocality: 'Shropshire',
        postalCode: 'SY2 6LG',
        addressCountry: 'GB',
      },
      openingHoursSpecification: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
        ],
        opens: '00:00',
        closes: '23:59',
      },
      sameAs: [
        'https://twitter.com/MaistroGroup',
        'https://www.linkedin.com/company/maistrogroup/',
      ],
    },
    {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'PLATFORM',
          item: 'https://maistro.com/platform/',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'SERVICES',
          item: 'https://maistro.com/services/',
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: 'ANALYSIS',
          item: 'https://maistro.com/analysis/',
        },
        {
          '@type': 'ListItem',
          position: 4,
          name: 'COMPANY',
          item: 'https://maistro.com/company/',
        },
        {
          '@type': 'ListItem',
          position: 5,
          name: 'LOGIN',
          item: 'https://portal.maistro.com/',
        },
      ],
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {schemaMarkup.map((v) => (
        <script key={v['@type']} type="application/ld+json">
          {JSON.stringify(v)}
        </script>
      ))}
      <script src="/zoominfo.js" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
