import { Link } from 'gatsby';
import React, { useState } from 'react';
import Button from 'components/button';
import MobileLogo from '../../assets/mobile-logo.inline.svg';
import DesktopLogo from '../../assets/desktop-logo.inline.svg';

const Header = ({ sticky, subNav }) => {
  const [showNav, setShowNav] = useState();

  return (
    <header
      className={`w-full pt-8 xl:pt-12 mb-8 xl:mb-4 text-base  z-30 ${
        sticky ? 'navbar navbar-sticky' : 'navbar'
      }`}
    >
      <div className="container">
        <div className="fixed z-50 p-2 pr-4 right-0 xl:hidden">
          <button
            type="button"
            className={`hamburger hamburger--squeeze js-hamburger appearance-none  ${
              showNav ? 'is-active' : ''
            }`}
            aria-label="Menu"
            aria-controls="navigation"
            onClick={() => setShowNav(!showNav)}
          >
            <span className="hamburger-box" type="button">
              <div className="hamburger-inner" />
            </span>
          </button>
        </div>
        <div className="xl:flex items-center xl:h-48 " id="headerWrapper">
          <div className="logo w-full max-w-xs pr-16 xl:pr-0">
            <Link to="/">
              <DesktopLogo />
            </Link>
          </div>
          <nav
            className={`bg-primary text-white fixed inset-0 left-auto w-full px-16 xl:px-0 font-light uppercase text-2xl md:text-3xl xl:bg-transparent xl:text-black xl:flex flex-1 xl:text-base xl:static xl:mb-auto justify-end transition duration-300 transform z-30 ${
              showNav ? 'translate-x-0' : 'translate-x-full'
            } xl:translate-x-0`}
          >
            <div className="max-w-xs w-full py-16 xl:hidden">
              <Link to="/">
                <MobileLogo />
              </Link>
            </div>
            <ul className="pb-16 md:px-16 xl:flex xl:px-0 xl:pr-6 xl:mr-6 xl:pb-0 xl:items-center xl:gap-x-6 capitalize font-medium xl:text-primary">
              <li className="mb-6 xl:mb-0">
                <Link
                  activeClassName="text-white bg-primary p-3"
                  to="/platform/"
                >
                  Platform
                </Link>
              </li>
              <li className="mb-6 xl:mb-0">
                <Link
                  activeClassName="text-white bg-primary p-3"
                  to="/services/"
                >
                  Services
                </Link>
              </li>
              <li className="mb-6 xl:mb-0">
                <Link
                  activeClassName="text-white bg-primary p-3"
                  to="/analysis/"
                >
                  Analysis
                </Link>
              </li>
              <li className="mb-6 xl:mb-0">
                <Link
                  activeClassName="text-white bg-primary p-3"
                  to="/suppliers/"
                >
                  Suppliers
                </Link>
              </li>
              <li className="mb-6 xl:mb-0 group">
                <Link
                  activeClassName="text-white bg-primary p-3"
                  to="/company/"
                >
                  About
                </Link>
                <ul className="text-secondary py-4 text-xl xl:absolute  xl:text-black xl:text-sm xl:py-2 md:hidden group-hover:block pt-4 overflow-hidden">
                  <li>
                    <Link
                      activeClassName="xl:text-primary"
                      className="pb-2 xl:px-4 xl:py-2 block  xl:bg-secondary xl:hover:text-primary"
                      to="/company/#vision"
                    >
                      Vision
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClassName="xl:text-primary"
                      className="pb-2 xl:px-4 xl:py-2 block  xl:bg-secondary xl:hover:text-primary"
                      to="/case-studies/one/"
                    >
                      Case studies
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="mb-6 xl:mb-0  ">
                <a href="mailto:enquiries@maistro.com?subject=Demo Request">
                  <Button text="Book a free demo" bg="primary" />
                </a>
              </li>
              <li className="mb-6 xl:mb-0  ">
                <a href="https://portal.maistro.com/">
                  <Button text="Login" bg="primary" outline />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className={`container ${sticky ? 'mt-8 xl:-mb-4 ' : ''}`}>
        {subNav}
      </div>
    </header>
  );
};

export default Header;
