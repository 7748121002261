/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import useSticky from 'hooks/useSticky';
import CookieConsent from 'react-cookie-consent';
import Header from './header';
import Footer from './footer';

const Layout = ({ children, home, subNav }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { isSticky } = useSticky();
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 250) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Close"
        style={{ background: '#017fad' }}
        buttonStyle={{
          background: '#FFFFFF',
          color: '#017fad',
          fontSize: '13px',
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{' '}
        <span style={{ fontSize: '10px', float: 'right' }}>
          Further details can be found in our{' '}
          <a href="/privacy-and-cookie-policy/">
            Privacy and Cookie policy
          </a>
        </span>
      </CookieConsent>
      <div className="bg-stone-light" id={home ? 'home' : 'pageWrapper'}>
        <Header
          siteTitle={data.site.siteMetadata?.title || 'Title'}
          sticky={isSticky}
          subNav={subNav}
        />

        <main className={subNav ? 'mt-20' : ''}>{children}</main>
        <Footer />
        <div
          className={
            showButton
              ? 'duration-200 fixed  bottom-0 right-0 p-6 transform translate-y-0'
              : 'duration-200  fixed  bottom-0 right-0 p-6 transform translate-y-full'
          }
        >
          <button
            type="button"
            className={
              showButton
                ? 'appearance-none flex items-center flex-col focus:outline-none transform translate-y-0'
                : 'appearance-none flex items-center flex-col focus:outline-none transform translate-y-full'
            }
            onClick={handleClick}
          >
            <span className="bg-primary--3 rounded-full h-12 w-12 block flex items-center justify-center transform -rotate-90 text-xl text-white  mb-4">
              {'>'}
            </span>
            <p className="text-primary--2 uppercase text-sm">Top of page</p>
          </button>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  subNav: PropTypes.node,
  home: PropTypes.bool,
};

Layout.defaultProps = {
  subNav: undefined,
  home: false,
};

export default Layout;
