import React from 'react';
import { Helmet } from 'react-helmet';
import MobileLogo from '../../assets/mobile-logo.inline.svg';
import LinkedIn from '../../assets/linkedin.inline.svg';
import Twitter from '../../assets/twitter.inline.svg';

const Footer = () => (
  <>
    <Helmet>
      <script
        src="https://code.jquery.com/jquery-3.3.1.min.js"
        integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
        crossOrigin="anonymous"
      />{' '}
    </Helmet>
    <footer className="bg-primary text-white uppercase py-24 text-base">
      <div className="container">
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="lg:w-3/4 flex flex-col justify-between">
            <div className="flex items-end mb-8">
              <div className="w-48 mr-12">
                <MobileLogo />
              </div>
              <div className="flex-1">
                <ul className="flex">
                  <li>
                    <a
                      aria-label="linkedin"
                      href="https://www.linkedin.com/company/maistrogroup/"
                      className="px-3 block"
                    >
                      <LinkedIn fill="white" width={26} height={26} />
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="MaistroGroup"
                      href="https://twitter.com/MaistroGroup"
                      className="px-3 block"
                    >
                      <Twitter width={26} height={26} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full max-w-sm mb-8 ">
            <nav className="flex">
              <ul className="pr-4 flex-1">
                <li className="mb-2">
                  <a href="/contact/">Contact</a>
                </li>
                <li className="mb-2 opacity-75">
                  <a href="https://portal.maistro.com/">Login</a>
                </li>
                <li className="mb-2 opacity-75">
                  <a href="/suppliers/">Suppliers</a>
                </li>
              </ul>
              <ul className="pr-4 flex-1">
                <li className="mb-2 opacity-75">
                  <a href="/privacy-and-cookie-policy/">Privacy Policy</a>
                </li>
                <li className="mb-2 opacity-75">
                  <a href="/terms-of-use/">Terms of use</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <noscript>
        <img
          src="https://ws.zoominfo.com/pixel/62bc4232888559008f6146db"
          width="1"
          height="1"
          style={{ display: 'none' }}
          alt="websights"
        />
      </noscript>
    </footer>
  </>
);

export default Footer;
